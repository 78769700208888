.app__projectsContainer{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 4rem;
}
.app__projectsContainer-project{
    width: 45%;
    height: 45vh;
    position: relative;
    display: flex;
    flex-direction:column;
    gap: 1rem;
}
.project-img{
    width: 100%;
    height: 88%;
    position: relative;
}
.project-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.project-info{
    display: flex;
    flex-direction: column;
    gap: .5rem;
    padding-top: .5rem;
}
.project-info div{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    font-family: var(--font-base);
    font-weight: 600;
}
.project-title{
    font-size: 1.4rem;
    font-family: var(--font-base);
    font-weight: 900;
    text-transform: capitalize;
    line-height: 1.2;
}
.project-img span{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00000028;
    transition: opacity .3s ease;
}
.project-img:hover.project-img  span{
    opacity: 0;
}
@media screen and (max-width: 1250px) {
    .app__projectsContainer-project{
        width: 45%;
        height: auto;
        margin-bottom: 3rem;
    }
}
@media screen and (max-width: 1000px) {
    .app__projectsContainer-project{
        width: 100%;
        margin-bottom: 0rem;
    }
    .project-info{
        padding: 1rem 2rem;
        margin-bottom: 2rem;
    }
}
@media screen and (max-width: 850px) {
    .app__projectsContainer-project{
        width: 100%;
        margin-bottom: 1.5rem;
    }
    .project-info{
        padding: 1rem 2rem;
    }
}
@media screen and (max-width: 550px){
    .app__projectsContainer-project{
        width: 100%;
        margin-bottom: 3.5rem;
    }
    .project-info{
        padding: 1rem ;
    }
    .project-title{
        font-size: 1.2rem;
    }
}
