.app__footer {
  width: 100%;
  height: 50vh;
  background-color: #1e1810;
  z-index: 11;
  padding-bottom: 1.5rem;
}
.app__footer-container {
  width: 100%;
  height: 85%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
  justify-content: space-between;
  /* padding-inline: 8rem; */
  padding-right: 8rem;
  padding-left: 6rem;
  /* background-color: red; */
}
.app__footer-container p {
  font-size: 16px;
  line-height: 1.5;
  color: #fff;
}
.app__footer-first-box {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  /* background-color: aqua; */
  /* padding-left: 6rem; */
}
.app__footer-first-box div {
  padding-left: 1.8rem;
}
.app__footer-first-box img {
  /* width: 50px; */
  aspect-ratio: 1/1 !important;
  height: 100px;
  /* background-color: rebeccapurple; */
}
.footer-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  width: 30px;
  height: 30px;
  font-size: 1.3rem;
  border-radius: 50%;
  cursor: pointer;
  background-color: #efefef38;
  position: relative;
}
.footer-icons:hover {
  background-color: var(--color-orange);
}
.facebook {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.app__footer-second-box,
.app__footer-third-box {
  min-width: 300px;
  display: flex;
  flex-direction: column;
  color: #fff;
  /* background-color: aqua; */
  padding-top: 1.3rem;
}
.app__footer-second-box h3,
.app__footer-third-box h3 {
  font-size: 1.5rem;
  margin-bottom: 3rem;
  color: #fff;
}
.app__footer-second-box p,
.app__footer-third-box p {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.footer-copyright {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff57;
  padding: 2rem 8rem 0 8rem;
  border-top: 1px solid #ffffff27;
}
.footer-copyright p a:hover {
  color: var(--color-orange);
}
@media screen and (max-width: 1200px) {
  .app__footer-container {
    padding-inline: 4rem;
    margin-bottom: 2rem;
  }
}
@media screen and (max-width: 1100px) {
  .app__footer {
    height: auto;
    padding-bottom: 2rem;
  }
  .app__footer-first-box div {
    padding-left: 0rem;
  }
}
@media screen and (max-width: 800px) {
  .app__footer {
    height: auto;
  }
  .app__footer-container {
    padding-inline: 2rem;
  }
  .app__footer-first-box img {
    width: 200px;
    aspect-ratio: 1/1 !important;
    /* height: 50px; */
    /* background-color: rebeccapurple; */
  }
  .app__footer-second-box,
  .app__footer-third-box {
    padding-top: 0;
  }
  .app__footer-second-box h3,
  .app__footer-third-box h3 {
    font-size: 1.3rem;
    margin: 1.2rem 0 0.6rem 0;
  }
  .app__footer-container p {
    font-size: 17px;
  }
  .footer-copyright {
    flex-direction: column;
    align-items: center;
    padding: 2rem 0 0 0;
  }
}
