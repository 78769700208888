.app__serviceOCA {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  padding-top: 6rem;
  background-color: #f7f5ef;
  overflow-x: hidden;
}
.app__serviceOCA-first-box {
  grid-column: 1 / 3;
  grid-row: 1 / 2;
  padding: 2rem 2rem 2rem 4rem;
}
.app__serviceOCA-first-box p,
.app__serviceOCA-first-box ul li {
  color: #000;
  /* opacity: 0.7; */
  font-size: 20px;
  line-height: 1.5;
}
.app__serviceOCA-first-box ul,
.app__serviceOCA-third-box ul {
  padding-left: 1rem;
}
.app__serviceOCA-img-box {
  grid-column: 1 / 3;
  grid-row: 2 / 2;
  padding-left: 4rem;
}
.app__serviceOCA-img-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.app__serviceOCA-third-box {
  grid-column: 3 / 6;
  grid-row: 1 / 4;
  padding: 2rem 4rem 2rem 6rem;
}
.app__serviceOCA-third-box ul li {
  color: #000;
  opacity: 0.7;
  font-size: 20px;
  line-height: 1.5;
}
.app__serviceOCA-third-box p {
  color: #000;
  /* opacity: 0.7; */
  font-size: 20px;
  line-height: 1.5;
}
.service_be-structure-container p {
  /* font-weight: 900; */
}
h3 {
  margin: 0.7rem 0;
  font-size: 1.2rem;
  color: #000;
  /* opacity: 0.7; */
}
@media screen and (max-width: 1000px) {
  .app__serviceOCA {
    display: flex;
    flex-direction: column;
    height: auto;
    padding-top: 2rem;
  }
  .app__serviceOCA-first-box,
  .app__serviceOCA-img-box,
  .app__serviceOCA-third-box {
    padding: 2rem;
    height: auto;
    width: 100%;
  }
  .app__serviceOCA-img-box {
    padding-right: 2rem;
  }
}
@media screen and (max-width: 650px) {
  .app__serviceOCA {
    padding-top: 2rem;
  }
}
