.app__header {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
  background: url("../../assets/building16.jpg");
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.app__header-container {
  width: 40%;
  z-index: 1;
}
.header_text-container {
  margin-bottom: 1.5rem;
}
.header_title-box {
  overflow: hidden;
}
.app__header-container h2 {
  font-size: 4.5vw !important;
  font-weight: bold;
  color: #fff;
  text-transform: capitalize;
  margin-top: 1rem;
}
.app__header-btm-container {
  margin-top: 1.3rem;
  display: flex;
  gap: 1rem;
}
.box-blur {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #000;
  opacity: 0.56;
  z-index: 0;
}
@media screen and (max-width: 1400px) {
  .app__header {
    align-items: flex-end;
  }
}
@media screen and (max-width: 900px) {
  .app__header-container {
    width: 100%;
    text-align: center;
  }
  .app__header-btm-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 3rem 0 0 0;
    gap: 1rem;
  }
}
@media screen and (max-width: 450px) {
  .app__header-container {
    width: 100%;
  }
  .app__header-container h2 {
    font-size: 7vw !important;
  }
}
