.app__team {
  width: 100%;
  height: auto;
  background-color: #f8f5f4;
  display: flex;
  flex-direction: row;
  padding-bottom: 10rem;
  gap: 2rem;
}
.app__team-left-box {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.app__team-left-box p {
  font-size: 16.5px;
  line-height: 1.4;
  color: #000;
  opacity: 0.7;
  font-family: var(--font-alt);
}
.app__team-right-box {
  flex: 1;
}
.app__team-right-box-img {
  width: 100%;
  aspect-ratio: 1/1;
}

@media screen and (max-width: 1200px) {
  .app__team {
    flex-direction: column;
    height: auto;
    gap: 2rem;
  }
  .app__team-right-box {
    width: 80%;
    aspect-ratio: 1/1;
    /* background-color: red; */
  }
}
