.app__singleProject {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}
.fixed-box {
  width: 100%;
  height: 100vh;
  background: url("../../assets/building13.jpg");
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}
.app__singleProject-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  transition: background-color 0.5s ease;
  padding: 6rem;
}
.bg-color-initial {
  background-color: #a59786;
}
.bg_color {
  background-color: #969c89;
}
.app__singleProject-container_first-box {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
}
.container_left-box {
  flex: 2;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}
.container_left-box h2 {
  font-size: 5vw;
  font-family: var(--font-base);
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  right: -40%;
  transform: translateY(-50%);
}
.container_right-box {
  flex: 3;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.container_right-box-img {
  width: 90%;
  height: 70%;
  object-fit: cover;
}
.container_right-box-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.app__singleProject-container_second-box {
  width: 80%;
  height: 100vh;
  margin-top: 10rem;
}
.container_second-box-img {
  width: 100%;
  height: 100%;
}
.container_second-box-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.box {
  height: 85vh;
  width: 10%;
}
.app__singleProject-container_third-box {
  width: 100%;
  height: 100vh;
  margin-top: 10rem;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}
.container_third-box-text p {
  font-size: 2rem;
}
.container_third-box-img {
  width: 80%;
  height: 70%;
}
.container_third-box-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 1000px) {
  .app__singleProject-container {
    padding: 2rem;
  }
  .app__singleProject-container_first-box {
    height: 80vh;
  }
  .container_right-box-img {
    width: 100%;
  }
}
@media screen and (max-width: 850px) {
  .fixed-box {
    height: 50vh;
  }
  .app__singleProject-container_first-box {
    width: 100%;
    height: 60vh;
  }
  .app__singleProject-container_second-box {
    height: auto;
    margin-top: 10rem;
  }
  .app__singleProject-container_third-box {
    width: 100%;
    height: auto;
    margin-top: 10rem;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-bottom: 5rem;
  }
}
@media screen and (max-width: 650px) {
  .app__singleProject-container_first-box {
    flex-direction: column;
  }
  .container_left-box h2 {
    font-size: 1.5rem;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .container_right-box-img {
    width: 100%;
  }
}
@media screen and (max-width: 550px) {
  .fixed-box {
    height: 50vh;
  }
  .app__singleProject-container_first-box {
    height: 50vh;
  }
}
