.app__cardImage{
    flex: 1;
    width: 85%;
    height:100%;
    position: relative;
}
.app__cardImage img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    z-index: 1;
}
.app__cardImage div{
    width: 100%;
    height: 100%;
    background : transparent;
    position: absolute;
    right: -30px;
    bottom: -30px; 
    border: 5px solid var(--color-orange);
    z-index: 0;
}