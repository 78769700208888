.app__contact{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-image: url('../../assets/contactimg.jpg');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #ffff;
}
.app__contact-container{
    flex: 1;
    width: 100%;
    height: auto;
    display: flex;
    gap: 1rem;
    padding: 6rem 4rem 0 4rem;
    border-bottom: 1px solid #ffffff2c;
    z-index: 2;
}
.app__contact-container_left-box{
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-top: 8rem;
    gap: 2rem;
}
.contact-title-box{
    height: fit-content;
    width: fit-content;
    overflow: hidden;
}
.app__contact-container_left-box h2{
    font-size: 4.3rem;
    font-family: var(--font-base);
    font-weight: 600;
    text-transform: uppercase;
}
.contact_section-infos{
    display: flex;
    gap: 2rem;
}
.contact_section-infos p{
    font-size: 18px;
    line-height: 1.2;
    opacity: .5;
}
.app__contact-container_right-box{
    flex: 1;
    padding-top: 5rem;
}
form{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
}
label {
    font-size: 1.5rem;
    opacity: .2;
}
input,
textarea{
    width: 100%;
    padding: .2rem 0 .2rem 1rem;
    margin-bottom: 3rem;
    outline: none;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px solid #ffffff2c;
    transition: padding .5s ease;
    background: transparent;
    color: #ffff;
}
form button{
    width: 100%;
    font-size: 14px;
    outline: none;
    border: none;
    font-weight: 500;
    text-align: start;
    background: transparent;
    cursor: pointer;
    padding: 2rem 0 2rem .5rem;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
    color: #ffff;
    opacity: .5;
}
form button{
    width: 170px;
    transition: .5s ease;
}
form button:hover{
    transform: scale(1.1);
}
input:focus,
textarea:focus{
    padding-top: 1rem;
}
.contact-copyright{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2.5rem 4rem;
    z-index: 2;
    opacity: .3;
}
.blur-span{
    width: 100%;
    height: 100%;
    position: absolute;  
    top: 0;
    left: 0;
    background-color: #000;
    opacity: .8;
    z-index: 1;
}
@media screen and (max-width: 1000px){
    .app__contact-container{
        align-items: center;
        padding: 6rem 2rem;
    }
    .app__contact-container_left-box{
        padding-top: 0rem;
    }
    .app__contact-container_left-box h2{
        font-size: 2.5rem;
    }
    input,
    textarea{
        margin-bottom: 1rem;
    }
    label{
        font-size: 1.2rem;
    }
}
@media screen and (max-width: 800px){
    .app__contact-container{
        flex-direction: column;
        padding: 0 2rem;
        width: 100%;
    }
    form{
        width: 100%;
    }
    .app__contact-container_left-box{
        padding-top: 8rem;
    }
    .app__contact-container_right-box{
        flex: 1;
        padding-top: 0;
        width: 100%;
    }
       form button{
        padding: 0;
    }
}
@media screen and (max-width: 550px){
   .app__contact-container_left-box h2{
        font-size: 1.5rem;
        text-align: center;
    }
    .contact-copyright{
        flex-direction: column;
        align-items: center;
    }
    .contact_section-infos p{
        font-size: 14px;
        line-height: 1;
    }
    
    
  
}