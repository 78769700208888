
.app__nextProject{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
}
.goBack{
    align-self: flex-end;
    margin: 2rem 4rem 0 0;
    padding-bottom:8px;
    border-bottom: 2px solid #000;
}
.container{
    width: 100%;
    height: 65vh;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    overflow: hidden;
    padding-top: 5.5rem;
}
.content-slider{
    flex: 1;
    position: relative;
    width: 100vw;
    border-bottom: 1px solid #000 ;
}
.first{
    border-top:1px solid #000;
}
.content-slider p{
    font-size: 4.6rem;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-family: var(--font-base);
    color: #000;
    padding-left: 4rem;
}
.slider_container{
    width: auto;
    height: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    overflow: hidden;
    background-color: #A59786;
    visibility:hidden;
    transition: .3s ease;
}
.wrapper{
    display: flex;
    align-items: center;
    animation: moving 8s linear infinite;
    height: 100%;
}
@keyframes moving{
    0%{
        transform: translateX(0);
    }
    100%{
        transform: translateX(-100%);
    }
}
.wrapper span {
    font-size: 4rem;
    white-space: nowrap;
    color: #fff;
    text-transform: capitalize;
}
.content-slider:hover .slider_container{
    visibility: visible;
    height: 100%;
}
.div_image{
    width: 250px;
    height: 80%;
    margin-inline: 25px;
}
.div_image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}
.app__nextProject-second-box{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 4rem;
    margin: 2rem 0;
}
.app__nextProject-second-box p{
    font-size: 1.5rem;
    font-family: var(--font-base);
}
@media screen and (max-width: 850px) {
    .container{
        height: 50vh;
    }
    .content-slider p{
        font-size: 2.6rem;
    }   
    .wrapper span {
        font-size: 2rem;
    }
    .app__nextProject-second-box p{
        font-size: 1.2rem;
    }
}
@media screen and (max-width: 550px) {
    .app__nextProject-second-box{
        flex-direction: column;
        align-items: center;
    }
    .app__nextProject-second-box{
        padding: 0 2rem;
    }
}



