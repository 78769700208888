.app__serviceOT{
    width: 100%;
    height: auto;
    display: flex;
    background-color: #Fff;
    margin-bottom: 6rem;
    overflow-x: hidden;
}
.app__serviceOT-left-box{
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 3rem 4rem 0 4rem;
}
.app__serviceOT-left-box h3{
    font-size: 1.7rem;
    font-weight: 500;
    color: #000;
    opacity: .7;
    font-family: var(--font-alt);
}
.app__serviceOT-left-box p{
    font-size: 20px;
    line-height: 1.7;
    margin-bottom:1rem ;
    margin-top:.5rem ;
    color: #000;
    font-family: var(--font-alt);
}
.app__serviceOT-right-box{
    flex: 1;
    padding: 10rem  4rem 4rem 4rem;
}
.app__serviceOT-right-box ul{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.app__serviceOT-right-box li{
    font-size: 1.5rem;
    list-style: none;
    line-height: 1.2;
    padding: .5rem 0;
    padding-left: 0.3rem;
    border-bottom: 1px  solid #00000015;
    text-transform: uppercase;
    color: #000;
    font-family: var(--font-alt);
}
@media screen and (max-width:1000px){
    .app__serviceOT{
        display: flex;
        flex-direction: column;
        gap: 3rem;
        height: auto;
        padding-top: 2rem;
    }
    .app__serviceOT-left-box{
        padding: 3rem 4rem 2rem 4rem;
    }
    .app__serviceOT-right-box{
        display: flex;
        flex-direction: column;
        padding-left: 1rem;
        gap: 1.5rem;
        padding: 0rem 4rem 2rem 4rem;
    }
}
@media screen and (max-width:650px){
    .serviceOT-left-box_item{
        height: 400px;
    }
    .app__serviceOT-left-box{
        padding:2rem;
    }
    .app__serviceOT-right-box{
        padding: 0rem 2rem 2rem 2rem;
    }
}